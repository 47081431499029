<template>
  <v-row class="newslist">
    <v-col
      class="newslist-tile"
      :cols="$vuetify.breakpoint.xs ? 12 : $vuetify.breakpoint.sm ? 6 : 4"
      v-for="news in newsList"
      :key="news.id"
    >
      <div class="newslist-tile-card">
        <v-img class="newslist-tile-image" :src="getTileImage(news)" />
        <div class="newslist-tile-date">{{ news.date }}</div>
        <div class="newslist-tile-title" v-html="news.title" />
      </div>
    </v-col>
    <div v-if="isShowMoreButton" class="newslist-more">
      <v-btn class="newslist-more-button" rounded color="white" @click="onClickMoreButton" height="56">
        <img class="newslist-more-button-more" :src="require('../../assets/images/pc/more.png')" />
      </v-btn>
    </div>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    newsList: {
      type: Array,
      default: () => []
    },
    isShowMoreButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['viewPage']),
    ...mapGetters('news', ['getAllNewsList']),
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions(['setHeader']),
    getTileImage(news) {
      return require(`../../assets/images/news/${news.image}`);
    },
    onClickMoreButton() {
      if (this.viewPage === 'news') {
        return;
      }
      this.setHeader({ pageName: 'news' });
      this.$router.push('/news');
    }
  }
};
</script>

<style lang="scss" scoped>
.newslist {
  background-color: #c2e5ff;
  padding: 8px;
  margin: 0;

  @media screen and (min-width: 960px) {
    min-width: 1000px;
    max-width: 1000px;
    border-radius: 32px;
    margin-top: 0;
    margin-bottom: 32px;
  }

  &-tile {
    display: flex;
    justify-content: center;

    &-card {
      position: relative;
      width: calc(100vw - 40px);
      height: calc(100vw - 50px);
      min-height: 300px;
      max-height: 350px;
      border-radius: 18px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      background-color: white;
      font-size: 14px;

      @media screen and (min-width: 600px) {
        width: calc(50vw - 40px);
        height: calc(50vw - 50px);
      }

      @media screen and (min-width: 960px) {
        width: 300px;
        height: 340px;
        font-size: 18px;
      }
    }

    &-image {
      background-color: white;
      background-position: center;
      background-size: contain;
      border-radius: 12px;
      margin: 12px;
      width: calc(100% - 24px);
      max-height: 240px;

      @media screen and (min-width: 960px) {
        left: 0;
        right: 0;
        margin: 12px auto;
      }
    }

    &-date {
      padding-left: 12px;
      text-align: left;

      @media screen and (min-width: 960px) {
        font-size: 16px;
      }
    }

    &-title {
      padding: 8px 12px;
      text-align: left;
    }
  }

  &-more {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 4px;

    @media screen and (min-width: 960px) {
      margin: 10px;
    }

    &-button {
      background-color: white;
      width: 140px;
      min-height: 40px;
      max-height: 40px;

      @media screen and (min-width: 960px) {
        width: 200px;
        min-height: 56px;
        max-height: 56px;
      }

      &-more {
        width: 100px;

        @media screen and (min-width: 960px) {
          width: 150px;
        }
      }
    }
  }
}
</style>
