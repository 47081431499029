<template>
  <div class="game-card">
    <v-img class="game-card-icon" :src="getGameIcon" />
    <div class="game-card-name">{{ game.name }}</div>
    <div class="game-card-detail">{{ game.detail }}</div>
    <div class="game-card-official">
      <a :href="game.official" target="_blank">{{ game.official }}</a>
    </div>
    <div class="game-card-apple">
      <a :href="game.storeApple" target="_blank">
        <v-img class="game-card-apple-img" :src="require('../../assets/images/pc/apple.png')" />
      </a>
    </div>
    <div class="game-card-google">
      <a :href="game.storeGoogle" target="_blank">
        <v-img class="game-card-google-img" :src="require('../../assets/images/pc/google.png')" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getGameIcon() {
      return require(`../../assets/images/game/${this.game.image}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.game-card {
  position: relative;
  width: 94vw;
  max-width: 500px;
  height: 206px;
  border-radius: 16px;
  background-color: #c2e5ff;
  margin-bottom: 16px;
  padding: 8px 16px;

  @media screen and (min-width: 600px) {
    height: 150px;
    max-width: 800px;
  }

  @media screen and (min-width: 960px) {
    height: auto;
    padding: 32px;
    border-radius: 32px;
    max-width: 1000px;
  }

  &-icon {
    border-radius: 16px;
    width: 88px;
    height: 88px;
    margin-top: 2px;

    @media screen and (min-width: 600px) {
      width: 100px;
      height: 100px;
      margin-top: 16px;
    }

    @media screen and (min-width: 960px) {
      border-radius: 32px;
      width: 150px;
      height: 150px;
      margin-top: 0;
    }
  }

  &-name {
    position: absolute;
    font-weight: bold;
    font-size: 1.2em;
    border-bottom: 3px dotted;
    border-color: #48a8fd;
    top: 104px;
    left: 16px;
    text-align: left;

    @media screen and (min-width: 600px) {
      top: 10px;
      left: 140px;
    }

    @media screen and (min-width: 960px) {
      top: 30px;
      left: 220px;
    }
  }

  &-detail {
    position: absolute;
    text-align: left;
    white-space: pre;
    top: 130px;
    left: 16px;
    text-align: left;
    white-space: pre-line;

    @media screen and (min-width: 600px) {
      top: 50px;
      left: 140px;
      width: auto;
      max-width: calc(100% - 285px);
    }

    @media screen and (min-width: 960px) {
      top: 75px;
      bottom: auto;
      left: 220px;
      width: auto;
    }
  }

  &-official {
    position: absolute;
    bottom: 10px;
    left: 16px;
    text-align: left;
    border-bottom: 2px solid;
    border-color: #48a8fd;

    @media screen and (min-width: 600px) {
      top: auto;
      left: 140px;
      bottom: 20px;
      width: auto;
    }

    @media screen and (min-width: 960px) {
      top: auto;
      left: 220px;
      bottom: 20px;
      width: auto;
    }
  }

  &-apple {
    position: absolute;
    top: 12px;
    right: 16px;

    @media screen and (min-width: 600px) {
      top: 20px;
    }

    @media screen and (min-width: 960px) {
      top: 40px;
      right: 40px;
    }

    &-img {
      border-radius: 8px;
      width: 130px;
      height: 38px;

      @media screen and (min-width: 700px) {
        width: 180px;
        height: 50px;
      }

      @media screen and (min-width: 960px) {
        width: 200px;
        height: 60px;
      }
    }
  }

  &-google {
    position: absolute;
    top: 60px;
    right: 16px;

    @media screen and (min-width: 600px) {
      top: 74px;
    }

    @media screen and (min-width: 960px) {
      top: 124px;
      bottom: auto;
      right: 40px;
    }

    &-img {
      border-radius: 8px;
      width: 130px;
      height: 38px;

      @media screen and (min-width: 700px) {
        width: 180px;
        height: 50px;
      }
      @media screen and (min-width: 960px) {
        width: 200px;
        height: 60px;
      }
    }
  }

  a {
    color: #000000de;
    text-decoration: none;

    &:visited {
      color: #000000de;
    }
  }
}
</style>
