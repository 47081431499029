export default {
  namespaced: true,
  state: {
    newsList: [
      {
        id: 11,
        type: 'news',
        image: 'news_sagashi.png',
        date: '2023/9/1',
        title: 'スマートフォンアプリ「おかずかん 間違い探し」をリリースしました。',
        tileColor: '#fffffb',
        detail: '',
      },
      {
        id: 10,
        type: 'news',
        image: 'noimage.png',
        date: '2023/7/1',
        title: '株式会社Start ITを100％子会社として設立しました。',
        tileColor: '',
        detail: ''
      },
      {
        id: 9,
        type: 'news',
        image: 'news_official.png',
        date: '2022/12/1',
        title:
          '<a href="https://okazukan.base.shop/" target="_blank"">おかずかんオフィシャルグッズショップ</a>を開設しました。',
        tileColor: '',
        detail: ''
      },
      {
        id: 8,
        type: 'news',
        image: 'noimage.png',
        date: '2022/7/1',
        title: 'メディウスバンドコスモロギー株式会社を100％子会社として設立しました。',
        tileColor: '',
        detail: ''
      },
      {
        id: 7,
        type: 'release',
        image: 'news_nonoshiri2.png',
        date: '2022/4/15',
        title: 'スマートフォンアプリ「罵りガール〜あやなちゃん〜」をリリースしました。',
        tileColor: '#fffffb',
        detail: ''
      },
      {
        id: 6,
        type: 'release',
        image: 'news_chili.png',
        date: '2021/7/14',
        title: 'スマートフォンアプリ「ラーゆせんぱい恋物語」をリリースしました。',
        tileColor: '#fffffb',
        detail: ''
      },
      {
        id: 5,
        type: 'release',
        image: 'news_nori.png',
        date: '2021/5/14',
        title: 'スマートフォンアプリ「きざみのりみちナンバーワンホストの道」をリリースしました。',
        tileColor: '#fffffb',
        detail: ''
      },
      {
        id: 4,
        type: 'release',
        image: 'news_nonoshiri.png',
        date: '2021/2/1',
        title: 'スマートフォンアプリ「罵りガール」をリリースしました。',
        tileColor: '#fffffb',
        detail: ''
      },
      {
        id: 3,
        type: 'release',
        image: 'news_okazukan.png',
        date: '2020/8/14',
        title: 'スマートフォンアプリ「おかずかん」をリリースしました。',
        tileColor: '#fffffb',
        detail: ''
      },
      {
        id: 2,
        type: 'news',
        image: 'noimage.png',
        date: '2020/7/1',
        title: 'HPをリニューアルしました。',
        tileColor: '',
        detail: ''
      },
      {
        id: 1,
        type: 'news',
        image: 'noimage.png',
        date: '2020/5/10',
        title: '飯田橋オフィスを開設しました。',
        tileColor: '',
        detail: ''
      }
    ]
  },
  getters: {
    getNewsListBySpTop: state => state.newsList.slice(0, 2),
    getNewsListByTop: state => state.newsList.slice(0, 3),
    getAllNewsList: state => state.newsList
  },
  actions: {},
  mutations: {}
};
