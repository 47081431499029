<template>
  <div class="privacy-contents">
    <h3>個人情報保護方針</h3>
    <p>
      株式会社コスモジャパン（以下、「当社」という。）は、当社にとって個人情報及び特定個人情報の保護が重大な責務であると認識しております。そこで個人情報保護理念と自ら定めた行動規範に基づき、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言致します。
    </p>
    <ul class="privacy-contents-list">
      <li>
        当社は、事業の内容及び規模を考慮した適切な個人情報の取得・利用及び提供を行い、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行ないません。また、そのための措置を講じます。
      </li>
      <br />
      <li>
        当社は個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
      </li>
      <br />
      <li>
        当社は個人情報の漏えい、滅失、き損などのリスクに対して、合理的な安全対策を講じて防止する規程、体制を構築し、継続的に向上させていきます。また、万一の際には速やかに是正措置を講じます。
      </li>
      <br />
      <li>
        当社は個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に対応致します。
      </li>
      <br />
      <li>
        個人情報保護マネジメントシステムは、当社を取り巻く環境の変化と実情を踏まえ、適時・適切に見直して継続的に改善をはかっていきます。
      </li>
    </ul>
    <br />
    <p style="textAlign:right">株式会社コスモジャパン 代表取締役 小町 克馬</p>
    <p style="textAlign:right">以上</p>

    <div class="privacy-contents-card">
      <h4>【個人情報保護方針に関するお問い合わせ先 兼 個人情報に関する苦情・相談窓口】</h4>
      <div>〒160-0022 東京都新宿区新宿5-11-30 新宿第五葉山ビル3F</div>
      <div>株式会社コスモジャパン 個人情報に関するお問い合わせ窓口</div>
      <div>メールアドレス: privacy@cosmo-japan.net</div>
    </div>

    <br />
    <br />
    <br />

    <h3>個人情報の取扱いについて</h3>
    <p>当社は取得する個人情報を以下に示す目的で利用いたします。</p>
    <ul class="privacy-contents-list">
      <li>ユーザーに関する個人情報</li>
      <ul class="privacy-contents-list-sub">
        <li>サービスおよびイベントに関する案内の送付</li>
        <li>カスタマーサポートサービスの提供</li>
        <li>問い合わせ及び相談への対応</li>
        <li>ゲーム開発、アンケート調査実施、モニター実施</li>
        <li>契約の履行</li>
      </ul>
      <br />
      <li>取引先をはじめとする他社の役員および従業員等の方に関する個人情報</li>
      <ul class="privacy-contents-list-sub">
        <li>業務上必要となる連絡、取引先情報管理、および支払・収入処理</li>
      </ul>
      <br />
      <li>取引先から委託を受けた個人情報</li>
      <ul class="privacy-contents-list-sub">
        <li>委託業務に関する契約の履行</li>
      </ul>
      <br />
      <li>採用応募者に関する個人情報</li>
      <ul class="privacy-contents-list-sub">
        <li>採用に際しての企業情報及び採用情報等の案内のため</li>
        <li>採用選考に際して必要となる申込受付、実施および連絡等のため</li>
        <li>採用に際して必要となる通知（配属先の決定、内定式及び入社式等の各種イベントの案内）のため</li>
      </ul>
      <p style="textAlign:right">以上</p>
    </ul>

    <br />
    <br />
    <br />

    <h3>保有個人データ及び第三者提供記録に関する事項の周知について</h3>
    <p>
      株式会社コスモジャパン（以下、「当社」という。）では、保有個人データの開示等（利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止）の請求及び第三者提供記録の開示に関する請求について、以下の事項を周知致します。
    </p>
    <ul class="privacy-contents-list">
      <li>当社の名称及び住所、代表者の氏名</li>
      <ul class="privacy-contents-list-sub">
        <p>
          名称: 株式会社コスモジャパン<br />
          住所: 〒160-0022 東京都新宿区新宿5-11-30 新宿第五葉山ビル3F<br />
          代表者: 小町 克馬
        </p>
      </ul>
      <li>個人情報保護管理者</li>
      <ul class="privacy-contents-list-sub">
        <p>
          代表取締役 小町 克馬<br />
          連絡先: privacy@cosmo-japan.net
        </p>
      </ul>
      <li>保有個人データの利用目的</li>
      <ul class="privacy-contents-list">
        <li>ユーザーに関する個人情報</li>
        サービスおよびイベントに関する案内の送付<br />
        カスタマーサポートサービスの提供<br />
        問い合わせ及び相談への対応<br />
        ゲーム開発、アンケート調査実施、モニター実施<br />
        契約の履行
        <li>取引先をはじめとする他社の役員および従業員等の方に関する個人情報</li>
        業務上必要となる連絡、取引先情報管理、および支払・収入処理
        <li>採用応募者に関する個人情報</li>
        採用に際しての企業情報及び採用情報等の案内のため<br />
        採用選考に際して必要となる申込受付、実施および連絡等のため<br />
        採用に際して必要となる通知（配属先の決定、内定式及び入社式等の各種イベントの案内）のため<br />
      </ul>
    </ul>
    <br />
    <li>保有個人データ取扱いに関する苦情の申し出先</li>
    <ul class="privacy-contents-list">
      <ul class="privacy-contents-list-sub">
        <p>
          保有個人データに関するお問合せにつきましては、下記窓口で受付けております。<br />
          〒160-0022 東京都新宿区新宿5-11-30 新宿第五葉山ビル3F<br />
          株式会社コスモジャパン 個人情報に関するお問い合わせ窓口<br />
          メールアドレス: privacy@cosmo-japan.net<br />
        </p>
      </ul>
    </ul>
    <li>当社の加入する認定個人情報保護団体について</li>
    <ul class="privacy-contents-list">
      <ul class="privacy-contents-list-sub">
        <p>
          当社の加入する認定個人情報保護団体はありません。
        </p>
      </ul>
    </ul>
    <li>開示等の手続きについて</li>
    <ul class="privacy-contents-list">
      <ul class="privacy-contents-list-sub">
        <p>
          開示等のご請求がございます場合には、上記個人情報に関するお問い合わせ窓口までご連絡をお願いします。<br />
          請求に必要な手順の説明と必要な申請書類などをお送りします。
        </p>
      </ul>
    </ul>
    <li>保有個人データ等の安全管理のために講じた措置</li>
    <ul class="privacy-contents-list">
      <ul class="privacy-contents-list-sub">
        <p>
          当社では、個人情報、特定個人情報の取扱いに関する規程、及び安全対策に関する規程を定め、<br />規程に則った措置（組織的、人的、物理的、技術的観点による安全管理措置）を講じております。
        </p>
      </ul>
    </ul>
    <p style="textAlign:right">以上</p>
    <br />

    <div class="rev">
      <p>
        制定: 2019年1月15日<br />
        改定: 2023年8月1日
      </p>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  mounted() {
    this.setViewPage({ pageName: 'privacy' });
  },
  methods: {
    ...mapActions(['setViewPage'])
  }
};
</script>

<style lang="scss" scoped>
.privacy-contents {
  width: 92vw;
  max-width: 1000px;
  text-align: left;
  padding: 8px;
  font-size: 0.8em;
  margin: 0 auto;

  &-list {
    margin-left: 20px;

    &-sub {
      margin-left: 20px;
    }
  }

  &-card {
    text-align: center;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    padding: 20px;
  }
}

.rev {
  text-align: right;
}
</style>
