<template>
  <div class="news">
    <div class="contents">
      <div id="news" />
      <ContentsTitle :path="getTitleNews" />
      <div class="contents-main">
        <div class="contents-container">
          <NewsList :newsList="newsList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NewsList from '@/components/parts/NewsList';
import ContentsTitle from '@/components/parts/ContentsTitle';

export default {
  components: {
    NewsList,
    ContentsTitle
  },
  computed: {
    ...mapGetters('news', ['getAllNewsList']),
    newsList() {
      return this.getAllNewsList;
    },
    getTitleNews() {
      return require('../../assets/images/pc/title_news.png');
    }
  },
  mounted() {
    this.setViewPage({ pageName: 'news' });
    this.setHeader({ pageName: 'news' });
  },
  methods: {
    ...mapActions(['setViewPage', 'setHeader'])
  }
};
</script>

<style lang="scss" scoped>
.contents {
  margin-top: 30px;
  width: 100%;
  text-align: center;

  &-main {
    display: flex;
    justify-content: center;
  }
}
</style>
