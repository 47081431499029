<template>
  <v-dialog v-model="isOpen">
    <v-card class="pa-4">
      <v-card-title class="pa-4">
        <div class="form-title">個人情報の取扱いについて</div>
      </v-card-title>
      <v-card-text class="pa-4">
        当社は、JIS Q
        15001:2017のA.3.4.2.5（A.3.4.2.4のうち本人から直接書面によって取得する場合の措置）に従い、個人情報を収集・保管いたします。<br />
        この入力フォームで取得する個人情報の取り扱いは下記3項の利用目的のためであり、この目的の範囲を超えて利用することはございません。<br />
        <br />
        １．組織の名称<br />
        組織の名称：株式会社コスモジャパン<br />
        <br />
        2．個人情報を関する管理者の氏名、所属及び連絡先<br />
        管理者名：代表取締役 小町克馬<br />
        連絡先：privacy@cosmo-japan.net<br />
        <br />
        3．個人情報の利用目的<br />
        ・当社のシステム開発、ITスクールサービス、SESビジネスパートナー、ゲームアプリについてのお問い合わせの方の個人情報は、お問い合わせにお答えする及びご要望のあった資料などをお送りするため<br />
        ・当社の採用応募の方の個人情報は、採用業務（選考、関連する連絡等）で使用するため<br />
        <br />
        4．個人情報の第三者提供<br />
        当社は、ご提供いただいた個人情報を次の場合を除き第三者に提供いたしません。<br />
        ・ご本人の同意がある場合<br />
        ・法令に基づく場合<br />
        ・人の生命、身体又は財産の保護のために必要がある場合であって、人の同意を得ることが困難であるとき<br />
        ・公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって本人の、同意を得ることが困難であるとき<br />
        ・国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき<br />
        <br />
        5．個人情報取扱いの委託<br />
        当社は、事業運営上、お客様により良いサービスを提供するために業務の一部を外部に委託しています。業務委託先に対しては、個人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などによりお客様の個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。<br />
        <br />
        6．個人情報の開示等の請求<br />
        お客様が当社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止、提供記録の開示）に関して、当社「個人情報に関するお問合わせ窓口」に申し出ることができます。その際、当社はご本人を確認させていただいたうえで、合理的な期間内に対応いたします。開示等の申し出の詳細につきましては、下記の「個人情報に関するお問い合わせ窓口」までお問い合わせください。<br />
        <br />
        〒160−0022 東京都新宿区新宿5−11−30新宿第五葉山ビル3F<br />
        株式会社コスモジャパン 個人情報に関するお問い合わせ窓口<br />
        メールアドレス：privacy@cosmo-japan.net<br />
        <br />
        7．個人情報を提供されることの任意性について<br />
        お客様が当社に個人情報を提供されるかどうかは、お客様の任意によるものです。ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。<br />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="isOpen = false">閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style lang="scss" scoped>
.form-title {
  width: 100%;
  text-align: center !important;
}

.comfirm-contents {
  border: 1px solid;
  border-radius: 4px;
  white-space: pre;
}
</style>
