<template>
  <div class="contact">
    <div class="contents">
      <div class="container" id="contact">
        <ContentsTitle :path="getTitle" />
        <v-spacer class="ma-3"></v-spacer>
        <h3>
          <p>
            下記フォームに必要事項をご⼊⼒頂き、ご送信くださいますようお願い致します。<br />
            後ほど担当の者より、ご連絡させていただきます。
          </p>
        </h3>
        <h5 v-if="!isEntry">
          <p>
            ※出来るだけ早めに御返事をさせていただきますが、状況により<br />
            お時間を頂いてしまうことがありますので、ご理解頂きますようお願い申し上げます。<br />
            また、お問い合わせいただいた内容によっては、お答えしかねる場合がありますので、あわせてご了承下さい。
          </p>
        </h5>
        <div v-if="isEntry">
          <EntryForm :form="entryForm" />
        </div>
        <div v-else>
          <InquiryForm :form="form" />
        </div>
        <v-row>
          <v-col cols="12"> 個人情報の取り扱いについて<a @click="onClickInfo">こちら</a>をご確認ください </v-col>
        </v-row>
        <div class="info-check">
          <v-checkbox v-model="isCheckInfo" center label="個人情報の取り扱いについて同意します" />
        </div>
        <v-row>
          <v-col cols="12">
            <v-btn
              rounded
              color="white"
              width="180px"
              height="40"
              :disabled="isDisabledConfirmButton"
              @click="onClickConfirm"
              >送信確認</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
    <PersonalInformationDialog ref="personalInformationDialog" />
    <ConfirmDialog ref="confirmDialog" :isEntry="isEntry" :form="formToConfirm" @onClickSend="onClickSend" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import { FormTypes, SexTypes } from '@/const';
import ContentsTitle from '@/components/parts/ContentsTitle';
import EntryForm from '@/components/parts/EntryForm';
import InquiryForm from '@/components/parts/InquiryForm';
import PersonalInformationDialog from '@/components/parts/PersonalInformationDialog';
import ConfirmDialog from '@/components/parts/ConfirmDialog';

export default {
  components: {
    ContentsTitle,
    EntryForm,
    InquiryForm,
    PersonalInformationDialog,
    ConfirmDialog,
  },
  data() {
    return {
      entryForm: {
        name: '', // 必須
        kana: '', // 必須
        birth: '', // 必須
        mail: '', // 必須
        phone: '', // 必須
        prefectures: '', // 必須
        type: null,
        occupation: '',
        message: '',
      },
      form: {
        type: null,
        name: '',
        kana: '',
        company: '',
        phone: '',
        mail: '',
        detail: '',
      },
      isCheckInfo: false,
    };
  },
  computed: {
    ...mapState(['viewHeader']),
    isEntry() {
      return this.$route.query.type == 'entry';
    },
    getTitle() {
      const filePath = this.isEntry ? 'titel_entry.png' : 'titel_form.png';
      return require(`../../assets/images/pc/${filePath}`);
    },
    isDisabledConfirmButton() {
      if (this.isEntry) {
        return (
          !this.isCheckInfo ||
          !this.entryForm.name ||
          !this.entryForm.kana ||
          !this.entryForm.birth ||
          !this.entryForm.mail ||
          !this.entryForm.phone ||
          !this.entryForm.prefectures
        );
      }
      return !this.isCheckInfo || !this.form.type || !this.form.name || !this.form.mail || !this.form.detail;
    },
    formToConfirm() {
      return this.isEntry ? this.entryForm : this.form;
    },
  },
  mounted() {
    this.setViewPage({ pageName: 'contact' });
    if (this.isEntry) {
      this.setHeader({ pageName: 'entry' });
    } else {
      this.setHeader({ pageName: 'contact' });
    }
  },
  methods: {
    ...mapActions(['setViewPage', 'setHeader']),
    onClickInfo() {
      this.$refs.personalInformationDialog.isOpen = true;
    },
    onClickConfirm() {
      this.$refs.confirmDialog.isOpen = true;
    },
    async onClickSend() {
      const params = new URLSearchParams();
      let url = '';
      if (this.isEntry) {
        url = 'https://www.cosmo-japan.net/php/mailform-recruit.php';
        params.append('name', this.entryForm.name); // 名前
        params.append('kana', this.entryForm.kana); // カナ
        params.append('birth', this.entryForm.birth); // 誕生日
        params.append('mail', this.entryForm.mail); // メール
        params.append('phone', this.entryForm.phone); // 電話
        params.append('prefectures', this.entryForm.prefectures); // 都道府県
        params.append('type', this.entryForm.type ? SexTypes[this.entryForm.type] : ''); // 性別
        params.append('occupation', this.entryForm.occupation); // 希望職種
        params.append('message', this.entryForm.message); // メッセージ
      } else {
        url = 'https://www.cosmo-japan.net/php/mailform-official.php';
        params.append('type', FormTypes[this.form.type]); // 問い合わせ種別
        params.append('name', this.form.name); // 名前
        params.append('kana', this.form.kana); // カナ
        params.append('company', this.form.company); // 会社名
        params.append('phone', this.form.phone); // 電話番号
        params.append('mail', this.form.mail); // メールアドレス
        params.append('detail', this.form.detail); // お問い合わせ内容
      }
      await axios
        .post(url, params)
        .then(() => {
          alert('送信しました。\n担当者からの連絡をお待ち下さい。');
          this.$refs.confirmDialog.isOpen = false;
        })
        .catch((error) => {
          console.log(error);
          alert('送信に失敗しました。\nしばらく時間を置いてからもう一度お試しください。');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  margin: 30px 0;
  width: 100%;
  text-align: center;

  justify-content: center;

  &-container {
    @media screen and (min-width: 960px) {
      min-width: 800px;
      max-width: 800px;
      margin-top: 0;
    }
  }
}

.info-check {
  display: flex;
  justify-content: center;
}
</style>
