<template>
  <div class="recruit">
    <div id="message" class="message">
      <ContentsTitle :path="getTitle" />

      <div>
        <h1>社員の「やりたい！」に応える会社</h1>
        <p />
        <h3>"社員の挑戦したい気持ちを実現できる会社をつくりたい"</h3>
        <p />
        <h4>
          そんな想いから、この会社は設立されました。<br />
          <p />
          私たちは、エンジニアが成長していける<br />
          エンジニア中心の会社を目指しています。<br />
          <p />
          自分の中で形にしたいと思い描いているものを<br />
          自分たちが作りあげ、世の中に届けていく。<br />
          <p />
          その中で<br />
          自分たちが作り手の喜びを感じられること<br />
          作り手が誰よりもワクワクできること<br />
          それこそが最も重要なものだと、私たちは考えています。<br />
          <p />
          そんな考えに共感してくれる仲間を、私たちは待っています。
        </h4>
      </div>
    </div>

    <div class="message-photo">
      <v-img :src="getMessageImage" />
    </div>

    <div id="recruirements" class="detail">
      <ContentsTitle :path="getTitleDetail" />
      <div>
        <h1>募集概要</h1>
        <p />
        <h4>
          安心して働ける環境をつくるために、当社では人間関係を<br />
          何よりも大切にしています。そのため、人間関係が悪くなるような<br />
          振る舞いは禁止としています。<br />
          <p />
          心理的な安全性の高い職場にすることが、一人ひとりの<br />
          パフォーマンスの向上につながっています。<br />
          <p />
          現在、営業やエンジニアなど、様々なポジションの採用を<br />
          実施しています。未経験の方でも当社のカルチャーに合う方は<br />
          コスモジャパンの一員としてお迎えしたいと考えています。<br />
          <p />
          少しでも興味を持って頂けた方は、お気軽にご応募ください。<br />
        </h4>
      </div>
    </div>

    <div class="btnarea">
      <div class="more">
        <h4 class="ma-2">詳しい求人概要はこちら</h4>
        <v-btn class="more-button" rounded color="white" @click="onClickMore" height="56">
          <img :src="require('../../assets/images/pc/more.png')" />
        </v-btn>
      </div>
      <div class="entry">
        <h4 class="ma-2">エントリーはこちら！</h4>
        <v-btn class="entry-button" rounded color="#FEDD74" @click="onClickEntry" height="56">
          <img :src="require('../../assets/images/pc/entry.png')" />
        </v-btn>
      </div>
    </div>

    <div class="ma-4">
      <h4>＼エンゲージにて採用ページ掲載中です／</h4>
      <a href="https://en-gage.net/cosmo-japan_saiyo/?banner=1" target="_blank"
        ><img src="https://en-gage.net/imageFile_new/company/widget_banner_B_sp_320×100.png" alt="engage" border="0"
      /></a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContentsTitle from '@/components/parts/ContentsTitle';

export default {
  components: {
    ContentsTitle,
  },
  computed: {
    getTitle() {
      return require('../../assets/images/pc/title_message.png');
    },
    getTitleDetail() {
      return require('../../assets/images/pc/title_requirements.png');
    },
    getMessageImage() {
      return require('../../assets/images/pc/message_img.png');
    },
  },
  mounted() {
    this.setViewPage({ pageName: 'recruit' });
    this.setHeader({ pageName: 'recruit' });
  },
  methods: {
    ...mapActions(['setViewPage', 'setHeader']),
    onClickMore() {
      window.open('https://www.cosmo-japan.net/requirements', '_blank');
    },
    onClickEntry() {
      this.setHeader({ pageName: 'entry' });
      this.$router.push({ name: 'Contact', query: { type: 'entry' } });

      // window.open('https://www.cosmo-japan.net/contact?type=entry', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit {
  margin: 30px 0;
  text-align: center;
}

.message {
  margin-bottom: 40px;

  &-photo {
    margin: 0 auto 40px;
    width: 50%;
    max-width: 400px;
  }
}

.detail {
  margin-bottom: 40px;
}

.btnarea {
  @media screen and (min-width: 768px) {
    display: flex;
  }
  gap: 24px;
  justify-content: center;
  margin: 48px 0;
}

.entry-button {
  background-color: #fedd74;
}

// .entry {
//   &__button {
//     .bg-white {
//       v-btn {
//         font-family: Futura;
//         font-weight: Bold;
//         color: #062751;
//         width: fit-content;
//         padding: 0.5% 1.5%;
//         margin: 0 auto;
//         font-weight: bold;
//         border: solid 3px #ffffff;
//       }
//     }
//     v-btn {
//       font-family: Futura;
//       font-weight: Bold;
//       color: #062751;
//       width: fit-content;
//       padding: 0.5% 1.5%;
//       margin: 0 auto;
//       font-weight: bold;
//       border: solid 3px #ffffff;
//     }
//   }
// }
</style>
