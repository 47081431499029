<template>
  <div class="business">
    <div class="contents">
      <div id="game" />
      <ContentsTitle :path="getTitleGame" />
      <p>当社で開発・運用しているゲーム製品一覧です</p>
      <div class="contents-main">
        <div class="contents-container">
          <div v-for="game in gameItems" :key="game.id">
            <GameCard :game="game" />
          </div>
        </div>
      </div>
    </div>
    <v-spacer v-if="!isScreenSmAndDown" class="pa-5" />

    <!-- クリエイティブ事業 -->
    <!-- SP -->
    <!-- <div v-if="isScreenSmAndDown" class="pa-4">
      <div id="creative" />
      <ContentsTitle :path="getTitleCreative" />
      <div class="contents-sp">
        <div>hogehoge</div>
        <div class="ma-5">
          <v-img class="contents-img" :src="require('../../assets/images/pc/creative_img.png')" />
          <h2 class="contents-col-title">アプリケーション開発</h2>
          <h5 class="ma-2">hogehoge</h5>
          <h5 class="ma-2"><a @click="goToApplication">> 詳しくはこちら</a></h5>
        </div>
      </div>
    </div> -->
    <!-- PC -->
    <!-- <div v-else class="contents">
      <div id="creative" />
      <ContentsTitle :path="getTitleCreative" />
      <p>hogehoge</p>
      <div class="contents-main">
        <v-row class="contents-row">
          <v-col class="contents-col text" cols="6">
            <div class="contents-col-text">
              <h2 class="contents-col-title">アプリケーション開発</h2>
              <p />
              <div class="contents-col-detail">
                <div>
                  <h5 class="contents-col-detail-text">hogehoge</h5>
                  <h5 class="ma-2"><a @click="goToApplication">> 詳しくはこちら</a></h5>
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="contents-col" cols="6">
            <v-img class="contents-img" :src="require('../../assets/images/pc/creative_img.png')" width="400" />
          </v-col>
          <v-col class="contents-col" cols="6">
            <v-img class="contents-img" :src="require('../../assets/images/pc/creative_img.png')" width="400" />
          </v-col>
          <v-col class="contents-col text" cols="6">
            <div class="contents-col-text">
              <h2 class="contents-col-title">ゲーム開発</h2>
              <p />
              <div class="contents-col-detail">
                <div>
                  <h5 class="contents-col-detail-text">hogehoge</h5>
                  <h5 class="ma-2"><a @click="goToSchool">> 詳しくはこちら</a></h5>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div> -->

    <!-- 受託事業 -->
    <!-- SP -->
    <div v-if="isScreenSmAndDown" class="pa-4">
      <div id="develop" />
      <ContentsTitle :path="getTitleDevelop" />
      <div class="contents-sp">
        <div>ご要望に応じてカスタマイズしたサービスをご提供致します。</div>
        <p />
        <div class="ma-5">
          <v-img class="contents-img" :src="require('../../assets/images/pc/develop-img.png')" />
          <h5 class="ma-2">
            当社の受託事業では、WEBシステム、サーバー構築/運用、第三者検証、ゲーム開発等お客様のご要望に沿ってサービスをご提供致します。<br />
            各分野に特化した技術を持つエンジニアが多数在籍しておりますので、上流工程から下流工程まで、貴社ご状況に応じて柔軟に対応が可能です。
          </h5>
          <h5 class="ma-2"><a @click="goToDevelop">> 詳しくはこちら</a></h5>
        </div>
      </div>
    </div>
    <!-- PC -->
    <div v-else class="contents">
      <div id="develop" />
      <ContentsTitle :path="getTitleDevelop" />
      <p>ご要望に応じてカスタマイズしたサービスをご提供致します。</p>
      <div class="contents-main">
        <v-row class="contents-row">
          <v-col class="contents-col" cols="6">
            <v-img class="contents-img" :src="require('../../assets/images/pc/develop-img.png')" width="400" />
          </v-col>
          <v-col class="contents-col text" cols="6">
            <div class="contents-col-text">
              <div class="contents-col-detail">
                <div>
                  <h5 class="contents-col-detail-text">
                    当社の受託事業では、WEBシステム、サーバー構築/運用、第三者検証、ゲーム開発等お客様のご要望に沿ってサービスをご提供致します。<br />
                    各分野に特化した技術を持つエンジニアが多数在籍しておりますので、上流工程から下流工程まで、貴社ご状況に応じて柔軟に対応が可能です。
                  </h5>
                  <h5 class="ma-2"><a @click="goToDevelop">> 詳しくはこちら</a></h5>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- ITスクール事業 -->
    <!-- SP -->
    <div v-if="isScreenSmAndDown" class="pa-4">
      <div id="school" />
      <ContentsTitle :path="getTitleSchool" />
      <div class="contents-sp">
        <div>未経験から最速でITエンジニアを育てる研修サービスです。</div>
        <div class="ma-5">
          <v-img class="contents-img" :src="require('../../assets/images/pc/school_img.png')" />
          <h2 class="contents-col-title">コスモITスクールサービス</h2>
          <h5 class="ma-2">
            「業務経験を積める研修」をコンセプトに、これまで未経験からITエンジニアを育ててきた研修ノウハウを元に、IT業界で実務を進める上で必要な知識に特化した研修を実施します。<br />IT業界未経験の方が、開発サポート/テスト業務等、ITの現場で活躍していくために必要となるスキル・業務経験を身につけることが可能なIT研修サービスです。
          </h5>
          <h5 class="ma-2"><a @click="goToSchool">> 詳しくはこちら</a></h5>
        </div>
      </div>
    </div>
    <!-- PC -->
    <div v-else class="contents">
      <div id="school" />
      <ContentsTitle :path="getTitleSchool" />
      <p>未経験から最速でITエンジニアを育てる研修サービスです。</p>
      <div class="contents-main">
        <v-row class="contents-row">
          <v-col class="contents-col text" cols="6">
            <div class="contents-col-text">
              <h2 class="contents-col-title">コスモITスクールサービス</h2>
              <p />
              <div class="contents-col-detail">
                <div>
                  <h5 class="contents-col-detail-text">
                    「業務経験を積める研修」をコンセプトに、これまで未経験からITエンジニアを育ててきた研修ノウハウから、IT業界で実務を進める上で必要な知識に特化した研修を実施します。<br />IT業界未経験の方が、開発サポート/テスト業務等、ITの現場で活躍していくために必要となるスキル・業務経験を身につけることが可能なIT研修サービスです。
                  </h5>
                  <h5 class="ma-2"><a @click="goToSchool">> 詳しくはこちら</a></h5>
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="contents-col" cols="6">
            <v-img class="contents-img" :src="require('../../assets/images/pc/school_img.png')" width="400" />
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- SES事業 -->
    <!-- SP -->
    <div v-if="isScreenSmAndDown" class="pa-4">
      <div id="ses" />
      <ContentsTitle :path="getTitleSes" />
      <div class="contents-sp">
        <div>お客様のご要望に合わせた人材支援を行います。</div>
        <p />
        <div class="ma-5">
          <v-img class="contents-img" :src="require('../../assets/images/pc/ses_img.png')" />
          <h5 class="ma-2">
            当社のSES（システムエンジニアリングサービス）事業では、経験豊富なITエンジニアとIT技術を求めている企業様をマッチングし、開発支援やインフラ支援（サーバー・ネットワークの構築など）、品質保証支援（テスト・QA業務）を得意としております。<br />当社のエンジニアの特徴として、ジュニアクラスの若手エンジニアから中堅レベル、シニアレベルのエンジニアがバランスよく在籍しており、上流工程から下流工程まで、ご要望に応じて適切なスキルの要員を幅広くご提案させて頂くことが可能です。
          </h5>
          <h5 class="ma-2"><a @click="goToSes">> 詳しくはこちら</a></h5>
        </div>
      </div>
    </div>
    <!-- PC -->
    <div v-else class="contents">
      <div id="ses" />
      <ContentsTitle :path="getTitleSes" />
      <p>お客様のご要望に合わせた人材支援を行います。</p>
      <div class="contents-main">
        <v-row class="contents-row">
          <v-col class="contents-col" cols="6">
            <v-img class="contents-img" :src="require('../../assets/images/pc/ses_img.png')" width="400" />
          </v-col>
          <v-col class="contents-col text" cols="6">
            <div class="contents-col-text">
              <div class="contents-col-detail">
                <div>
                  <h5 class="contents-col-detail-text">
                    当社のSES（システムエンジニアリングサービス）事業では、経験豊富なITエンジニアとIT技術を求めている企業様をマッチングし、開発支援やインフラ支援（サーバー・ネットワークの構築など）、品質保証支援（テスト・QA業務）を得意としております。<br />当社のエンジニアの特徴として、ジュニアクラスの若手エンジニアから中堅レベル、シニアレベルのエンジニアがバランスよく在籍しており、上流工程から下流工程まで、ご要望に応じて適切なスキルの要員を幅広くご提案させて頂くことが可能です。
                  </h5>
                  <h5 class="ma-2"><a @click="goToSes">> 詳しくはこちら</a></h5>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-spacer v-if="!isScreenSmAndDown" class="pa-5" />
    <!-- SP -->
    <!-- <div v-if="isScreenSmAndDown" class="pa-4">
      <div id="ses" />
      <ContentsTitle :path="getTitleSolution" />
      <div class="contents-sp">
        <div>
          多種多様な業界に対し、主に3つの分野に特化してソリューションをご提供いたします
        </div>
        <div class="ma-5">
          <v-img :src="require('../../assets/images/pc/web.png')" />
          <h2 class="contents-col-title">Web開発/アプリケーション開発</h2>
          <p />
          <h5>
            Webサイト・スマートフォンサイトのデザイン、ホームページ制作からWebアプリケーションのシステム開発まで、お客様のニーズに合わせ幅広くご提案させていただきます。
          </h5>
        </div>
        <div class="ma-5">
          <v-img :src="require('../../assets/images/pc/infra.png')" />
          <h2 class="contents-col-title">ネットワーク・サーバー構築</h2>
          <p />
          <h5>
            Linux系サーバーやWindows系サーバーに精通したサーバーエンジニアが、お客様の要望に合わせたシステム設計を行い、環境の構築、運用保守まで一貫サポートいたします。
          </h5>
        </div>
        <div class="ma-5">
          <v-img :src="require('../../assets/images/pc/test.png')" />
          <h2 class="contents-col-title">ソフトウェアテスト・第三者検証</h2>
          <p />
          <h5>
            ソフトウェアテストに関する専門的な資格・知識を有したテストのスペシャリストが、お客様の要望に合わせ最適かつ柔軟なソフトウェア検証サービスをご提案いたします。
          </h5>
        </div>
      </div>
    </div> -->
    <!-- PC -->
    <!-- <div v-else class="contents">
      <div id="ses" />
      <ContentsTitle :path="getTitleSolution" />
      <p>多種多様な業界に対し、主に3つの分野に特化してソリューションをご提供いたします</p>
      <div class="contents-main">
        <v-row class="contents-row">
          <v-col class="contents-col" cols="6">
            <v-img :src="require('../../assets/images/pc/web.png')" width="400" />
          </v-col>
          <v-col class="contents-col" cols="6">
            <div>
              <h2 class="contents-col-title">Web開発/アプリケーション開発</h2>
              <p />
              <div class="contents-col-detail">
                <h5 class="contents-col-detail-text">
                  Webサイト・スマートフォンサイトのデザイン、ホームページ制作からWebアプリケーションのシステム開発まで、お客様のニーズに合わせ幅広くご提案させていただきます。
                </h5>
              </div>
            </div>
          </v-col>
          <v-col class="contents-col text" cols="6">
            <div class="contents-col-text">
              <h2 class="contents-col-title">ネットワーク・サーバー構築</h2>
              <p />
              <div class="contents-col-detail">
                <h5 class="contents-col-detail-text">
                  Linux系サーバーやWindows系サーバーに精通したサーバーエンジニアが、お客様の要望に合わせたシステム設計を行い、環境の構築、運用保守まで一貫サポートいたします。
                </h5>
              </div>
            </div>
          </v-col>
          <v-col class="contents-col" cols="6">
            <v-img :src="require('../../assets/images/pc/infra.png')" width="400" />
          </v-col>
          <v-col class="contents-col" cols="6">
            <v-img :src="require('../../assets/images/pc/test.png')" width="400" />
          </v-col>
          <v-col class="contents-col text" cols="6">
            <div class="contents-col-text">
              <h2 class="contents-col-title">ソフトウェアテスト・第三者検証サービス</h2>
              <p />
              <div class="contents-col-detail">
                <h5 class="contents-col-detail-text">
                  ソフトウェアテストに関する専門的な資格・知識を有したテストのスペシャリストが、お客様の要望に合わせ最適かつ柔軟なソフトウェア検証サービスをご提案いたします。
                </h5>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContentsTitle from '@/components/parts/ContentsTitle';
import GameCard from '@/components/parts/GameCard';

export default {
  components: {
    ContentsTitle,
    GameCard,
  },
  data() {
    return {
      gameItems: [
        {
          id: 6,
          name: 'おかずかん 間違い探し',
          image: 'sagashi.png',
          detail: 'おかずかんのイラストが間違い探しになった！\nどれだけ早く間違いを見つけられるかな…！？',
          storeApple: 'https://apps.apple.com/app/id6462363569',
          storeGoogle: 'https://play.google.com/store/apps/details?id=net.cosmojapan.okazusagashi',
        },
        {
          id: 5,
          name: '罵りガール〜あやなちゃん〜',
          image: 'nonoshiri2.png',
          detail: '今度の罵りガールはJKギャル！？\nこれは当たって砕けろ！プレゼント攻撃だ！',
          official: 'https://www.cosmo-japan.net/nonoshiri2_official/',
          storeApple: 'https://apps.apple.com/app/id1614071391',
          storeGoogle: 'https://play.google.com/store/apps/details?id=net.cosmojapan.nonoshiri2',
        },
        {
          id: 4,
          name: 'ラーゆせんぱい恋物語',
          image: 'chilioil.png',
          detail: '街の平和を守る辛口ヒーロー「ラーゆせんぱい」\nラーゆせんぱいの熱い物語が、ここに開幕！',
          official: 'https://www.cosmo-japan.net/chirioil_official/',
          storeApple: 'https://apps.apple.com/app/id1575315710',
          storeGoogle: 'https://play.google.com/store/apps/details?id=net.cosmojapan.chirioil',
        },
        {
          id: 3,
          name: 'きざみのりみちナンバーワンホストの道',
          image: 'norimichi.png',
          detail: 'ナンバーワンホストを目指す「きざみのりみち」\nナンバーワンになれるかはあなた次第！？',
          official: 'https://www.cosmo-japan.net/norimichi_official/',
          storeApple: 'https://apps.apple.com/app/id1561999081',
          storeGoogle: 'https://play.google.com/store/apps/details?id=net.cosmojapan.norimichi',
        },
        {
          id: 2,
          name: '罵りガール',
          image: 'nonoshiri.png',
          detail: '可愛い女の子に慰められたい、甘やかされたい、\nそんなあなたが出会った彼女はなんと罵倒少女！',
          official: 'https://www.cosmo-japan.net/nonoshiri_official/',
          storeApple: 'https://apps.apple.com/app/id1550024514',
          storeGoogle: 'https://play.google.com/store/apps/details?id=net.cosmojapan.nonoshiri',
        },
        {
          id: 1,
          name: 'おかずかん',
          image: 'okazukan.png',
          detail: '一風変わったキャラクターを集める放置ゲーム！\n図鑑のコンプリートを目指そう！',
          official: 'https://www.cosmo-japan.net/okazukan_official/',
          storeApple: 'https://apps.apple.com/app/id1525747193',
          storeGoogle: 'https://play.google.com/store/apps/details?id=net.cosmojapan.okazu',
        },
      ],
    };
  },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getTitleGame() {
      return require('../../assets/images/pc/titel_game.png');
    },
    getTitleCreative() {
      return require('../../assets/images/pc/titel_creative.png');
    },
    getTitleDevelop() {
      return require('../../assets/images/pc/title_develop.png');
    },
    getTitleSchool() {
      return require('../../assets/images/pc/title_school.png');
    },
    getTitleSes() {
      return require('../../assets/images/pc/title_ses.png');
    },
    getTitleSolution() {
      return require('../../assets/images/pc/titel_it.png');
    },
  },
  mounted() {
    this.setViewPage({ pageName: 'business' });
    this.setHeader({ pageName: 'business' });
  },
  methods: {
    ...mapActions(['setViewPage', 'setHeader']),
    goToSchool() {
      this.$emit('goToSchool');
    },
    goToSes() {
      this.$emit('goToSes');
    },
    goToDevelop() {
      this.$emit('goToDevelop');
    },
    goToApplication() {
      this.$router.push('/application');
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  margin-top: 30px;
  width: 100%;
  text-align: center;

  &-main {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  &-container {
    @media screen and (min-width: 960px) {
      min-width: 1000px;
      max-width: 1000px;
      margin-top: 0;
    }
  }

  &-row {
    @media screen and (min-width: 960px) {
      min-width: 1000px;
      max-width: 1000px;
    }
  }

  &-col {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 960px) {
      min-width: 500px;
    }

    &-title {
      width: 100%;
      @media screen and (max-width: 960px) {
        margin: 12px 0;
      }
    }
    &-detail {
      display: flex;
      justify-content: center;
      align-items: center;

      &-text {
        width: 400px;
      }
    }
  }

  &-sp {
    text-align: center;
  }

  &-img {
    border-radius: 30px;
  }
}
</style>
