<template>
  <div class="top">
    <div class="contents">
      <div class="contents-main">
        <div class="contents-left">
          <div v-if="isMobile" class="contents-left-sp">
            <transition name="spfadeimg1">
              <v-img
                v-if="isShowImg1"
                class="contents-left-sp-img1"
                :src="require('../../assets/images/sp/sp002.jpeg')"
              />
            </transition>
            <transition name="spfadeimg2">
              <v-img
                v-if="isShowImg2"
                class="contents-left-sp-img2"
                :src="require('../../assets/images/sp/sp001.jpeg')"
              />
            </transition>
          </div>
          <div v-else>
            <v-img class="contents-left-img" :src="require('../../assets/images/pc/wearepic.png')" />
          </div>
        </div>
        <div class="contents-right">
          <v-img class="contents-right-weare" :src="require('../../assets/images/pc/weare.png')" />
          <div class="contents-right-text">
            私たちは、エンジニアが成長していける<br />
            エンジニア中心の会社を目指しています。
          </div>
        </div>
      </div>
    </div>
    <div class="contents">
      <ContentsTitle :path="getTitleNews" />
      <div class="contents-main">
        <NewsList :newsList="newsList" :isShowMoreButton="true" />
      </div>
    </div>
    <div class="contents">
      <v-img class="contents-sc" :src="require('../../assets/images/pc/title_sc.png')" />
      関連企業
      <div class="contents-sc-icons">
        <div>
          <v-img
            class="contents-scimg"
            width="150"
            :aspect-ratio="1"
            :src="require('../../assets/images/pc/mbk_logo.png')"
            @click="onClickScLogo('https://m-b-kosmo.net/')"
          />
        </div>
        <div>
          <v-img
            class="contents-scimg"
            width="150"
            :aspect-ratio="1.5"
            :src="require('../../assets/images/pc/st_logo.png')"
            @click="onClickScLogo('https://start-it.tokyo/')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NewsList from '@/components/parts/NewsList';
import ContentsTitle from '@/components/parts/ContentsTitle';

export default {
  components: {
    NewsList,
    ContentsTitle,
  },
  data() {
    return {
      // sp版アニメーション用
      isShowImg1: false,
      isShowImg2: false,
    };
  },
  computed: {
    ...mapGetters('news', ['getNewsListBySpTop', 'getNewsListByTop']),
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isMobile() {
      // widthが640px以下の時だけ表示するやつ
      return this.$vuetify.breakpoint.width < 640;
    },
    newsList() {
      return this.isScreenSmAndDown ? this.getNewsListBySpTop : this.getNewsListByTop;
    },
    getTitleNews() {
      return require('../../assets/images/pc/title_news.png');
    },
  },
  mounted() {
    this.setViewPage({ pageName: 'top' });
    this.setHeader({ pageName: 'top' });

    setTimeout(() => {
      this.isShowImg1 = true;
    }, 500);
    setTimeout(() => {
      this.isShowImg2 = true;
    }, 2000);
  },
  methods: {
    ...mapActions(['setViewPage', 'setHeader']),
    onClickScLogo(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  margin-top: 30px;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 640px) {
    margin-top: 0;
  }

  @media screen and (min-width: 960px) {
    margin-top: 60px;
  }

  &-title {
    text-align: center;
    display: flex;
    position: relative;
    height: 50px;

    @media screen and (min-width: 960px) {
      height: 80px;
    }

    &-img {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  &-main {
    @media screen and (min-width: 640px) {
      display: flex;
      justify-content: center;
    }
  }

  &-left {
    display: flex;
    padding: 20px;

    @media screen and (min-width: 960px) {
      min-width: 500px;
      max-width: 500px;
    }

    &-img {
      width: 36vw;

      @media screen and (min-width: 960px) {
        width: 450px;
      }
    }

    // spのときだけアニメさせる
    &-sp {
      position: relative;
      width: 100%;
      height: 100vw;
      // background-color: red;

      &-img1 {
        position: absolute;
        border-radius: 50%;
        width: 80%;
      }
      &-img2 {
        position: absolute;
        border-radius: 50%;
        width: 60%;
        right: 0;
        bottom: 20px;
      }
    }
  }

  &-right {
    position: relative;

    @media screen and (max-width: 640px) {
      margin-top: -100px;
      padding: 40px;
      margin-bottom: 20px;
    }

    @media screen and (min-width: 640px) {
      margin-top: 20px;
      padding: 0;
      margin-bottom: 0;
    }

    @media screen and (min-width: 960px) {
      min-width: 500px;
      max-width: 500px;
    }

    &-weare {
      position: relative;
      left: -10px;

      @media screen and (max-width: 640px) {
        top: 0;
        width: 60vw;
      }
      @media screen and (min-width: 640px) {
        top: 60px;
        width: 40vw;
      }

      @media screen and (min-width: 960px) {
        top: 80px;
        left: -80px;
        width: 400px;
      }
    }

    &-text {
      position: relative;
      font-weight: bold;
      text-align: left;
      top: 10px;
      left: 8px;
      line-height: 2;

      @media screen and (max-width: 640px) {
        font-size: 1.4em;
        width: 100%;
        text-align: center;
      }
      @media screen and (min-width: 640px) {
        font-size: 1.4em;
        top: 80px;
      }
      @media screen and (min-width: 768px) {
        top: 50px;
        left: 30px;
        font-size: 1.3em;
      }

      @media screen and (min-width: 960px) {
        top: 120px;
        left: 10px;
      }
    }
  }

  &-sc {
    position: relative;
    margin: 0 auto;
    width: 70vw;

    @media screen and (max-width: 640px) {
      margin-top: 40px;
    }

    @media screen and (min-width: 768px) {
      width: 30vw;
    }

    &-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }

  &-scimg {
    position: relative;
    width: 25vw;
    margin: 30px auto;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      width: 10vw;
    }
  }
}

.spfadeimg1-enter-active {
  transition: all 2s ease;
}
.spfadeimg1-enter {
  opacity: 0;
  transform: translateX(20px);
}
.spfadeimg2-enter-active {
  transition: all 2s ease;
}
.spfadeimg2-enter {
  opacity: 0;
  transform: translateX(-20px);
}
</style>
