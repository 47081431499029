import Vue from 'vue';
import Router from 'vue-router';
import Top from '../components/views/Top';
import News from '../components/views/News';
import About from '../components/views/About';
import Business from '../components/views/Business';
import Contact from '../components/views/Contact';
import Recruit from '../components/views/Recruit';

import Privacy from '../components/views/Privacy';
import Application from '../components/views/Application';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Top',
      component: Top
    },
    {
      path: '/news',
      name: 'News',
      component: News
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/business',
      name: 'Business',
      component: Business
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/recruit',
      name: 'Recruit',
      component: Recruit
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/application',
      name: 'Application',
      component: Application
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    const getPos = (to, from, pos) => {
      const hash = to.hash;
      // stickyにしている分のオフセット
      const offset = { x: 0, y: window.innerWidth < 640 ? 10 : 80 };

      // 遷移先がハッシュの場合
      if (hash) {
        if (to.name == from.name) {
          // 同一ページ内の場合はsmoothでスクロール
          return { selector: hash, behavior: 'smooth', offset: offset };
        } else {
          // ページ遷移する場合はsmooth不要
          return { selector: hash, offset: offset };
        }
      }
      // 遷移先にハッシュがなく、同一ページの場合
      else if (to.name == from.name && from.hash) {
        return { selector: 'body', behavior: 'smooth', offset: offset };
      }
      // 保存したPositionがある場合
      else if (pos) return pos;
      // デフォルト
      else return { x: 0, y: 0, offset: offset };
    };

    // 同一ページの場合はすぐにスクロール
    if (to.name == from.name) return getPos(to, from, savedPosition);
    // 異なるページの場合は、遷移アニメーションがあるので、少し待ってからスクロールする
    else {
      if (!to.hash) {
        return new Promise(resolve => setTimeout(() => resolve(getPos(to, from, savedPosition)), 400));
      } else {
        return new Promise(resolve => setTimeout(() => resolve(getPos(to, from, savedPosition)), 600));
      }
    }
  }
});

export default router;
