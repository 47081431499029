<template>
  <div class="about">
    <v-row class="contents-row">
      <v-col :cols="isScreenSmAndDown ? 12 : 9">
        <div id="profile" />
        <ContentsTitle :path="getTitleProfile" />
        <div class="contents-profile">
          <v-row class="contents-profile-about">
            <v-col cols="3">会社名</v-col>
            <v-col cols="9">株式会社コスモジャパン</v-col>
            <v-col cols="3">設立</v-col>
            <v-col cols="9">2019年1月</v-col>
            <v-col cols="3">資本金</v-col>
            <v-col cols="9">500万円</v-col>
            <v-col cols="3">代表者</v-col>
            <v-col cols="9">小町 克馬</v-col>
            <v-col cols="3">事業内容</v-col>
            <v-col cols="9">
              ゲーム、アプリケーション、ソフトウェアの企画・開発・運用<br />
              ITサービス、エンジニアリングサービス事業
            </v-col>
            <v-col cols="3">所在地</v-col>
            <v-col cols="9">
              <p>
                【本社】〒160−0022<br />
                東京都新宿区新宿5−11−30<br />
                新宿第五葉山ビル3F
              </p>
              <p>
                【飯田橋オフィス】〒162−0824<br />
                東京都新宿区揚場町2−20<br />
                大和ビル3E
              </p>
            </v-col>
          </v-row>
          <!-- <div class="contents-profile-donguri">
            <v-img :src="require('../../assets/images/pc/character.png')" />
          </div> -->
        </div>
      </v-col>

      <v-col :cols="isScreenSmAndDown ? 12 : 9">
        <div id="history" />
        <ContentsTitle :path="getTitleHistory" />
        <div class="contents-history">
          <v-row class="contents-history-table">
            <!-- 2019 -->
            <v-col cols="3">
              <v-img :src="require('../../assets/images/pc/2019.png')" width="100" />
            </v-col>
            <v-col cols="9">
              <div class="contents-history-detail">
                <div class="contents-history-month">1月</div>
                <div class="contents-history-text">資本金1万円で株式会社コスモジャパンを設立</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">12月</div>
                <div class="contents-history-text">売上高2800万円で初年度を終了</div>
              </div>
            </v-col>
            <!-- 2020 -->
            <v-col cols="3">
              <v-img :src="require('../../assets/images/pc/2020.png')" width="100" />
            </v-col>
            <v-col cols="9">
              <div class="contents-history-detail">
                <div class="contents-history-month">2月</div>
                <div class="contents-history-text">資本金を100万円に増資</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">2月</div>
                <div class="contents-history-text">本社を新宿区新宿に移転</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">5月</div>
                <div class="contents-history-text">新宿区揚場町に飯田橋オフィスを設立</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">8月</div>
                <div class="contents-history-text">ゲームアプリ「おかずかん」をリリース</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">12月</div>
                <div class="contents-history-text">売上高5800万円で2年度を終了</div>
              </div>
            </v-col>
            <!-- 2021 -->
            <v-col cols="3">
              <v-img :src="require('../../assets/images/pc/2021.png')" width="100" />
            </v-col>
            <v-col cols="9">
              <div class="contents-history-detail">
                <div class="contents-history-month">2月</div>
                <div class="contents-history-text">ゲームアプリ「罵りガール」をリリース</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">5月</div>
                <div class="contents-history-text">
                  ゲームアプリ「きざみのりみちナンバーワンホストの道」をリリース
                </div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">7月</div>
                <div class="contents-history-text">ゲームアプリ「ラーゆせんぱい恋物語」をリリース</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">11月</div>
                <div class="contents-history-text">ゲームアプリ「おかずかん」を多言語化<br />174か国へ配信開始</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">12月</div>
                <div class="contents-history-text">売上高9300万円で3年度を終了</div>
              </div>
            </v-col>
            <!-- 2022 -->
            <v-col cols="3">
              <v-img :src="require('../../assets/images/pc/2022.png')" width="100" />
            </v-col>
            <v-col cols="9">
              <div class="contents-history-detail">
                <div class="contents-history-month">1月</div>
                <div class="contents-history-text">社員数20名を突破</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">4月</div>
                <div class="contents-history-text">ゲームアプリ「罵りガール〜あやなちゃん〜」をリリース</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">4月</div>
                <div class="contents-history-text">資本金を500万円に増資</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">7月</div>
                <div class="contents-history-text">メディウスバンドコスモロギー株式会社を100％子会社として設立</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">10月</div>
                <div class="contents-history-text">ゲームイラスト制作受託業務開始</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">12月</div>
                <div class="contents-history-text">おかずかんオフィシャルグッズの販売開始</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">12月</div>
                <div class="contents-history-text">売上高1億4000万円で4年度を終了</div>
              </div>
            </v-col>
            <!-- 2023 -->
            <v-col cols="3">
              <v-img :src="require('../../assets/images/pc/2023.png')" width="100" />
            </v-col>
            <v-col cols="9">
              <div class="contents-history-detail">
                <div class="contents-history-month">1月</div>
                <div class="contents-history-text">ITスクール事業開始</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">7月</div>
                <div class="contents-history-text">株式会社Start ITを100％子会社として設立</div>
              </div>
              <div class="contents-history-detail">
                <div class="contents-history-month">9月</div>
                <div class="contents-history-text">ゲームアプリ「おかずかん 間違い探し」をリリース</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContentsTitle from '@/components/parts/ContentsTitle';

export default {
  components: {
    ContentsTitle
  },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getTitleProfile() {
      return require('../../assets/images/pc/title_companyprofile.png');
    },
    getTitleHistory() {
      return require('../../assets/images/pc/titel_history.png');
    },
    getHistory2019() {
      return require('../../assets/images/pc/2019.png');
    }
  },
  mounted() {
    this.setViewPage({ pageName: 'about' });
    this.setHeader({ pageName: 'about' });
  },
  methods: {
    ...mapActions(['setViewPage', 'setHeader'])
  }
};
</script>

<style lang="scss" scoped>
.contents {
  // margin-top: 30px;
  // width: 100%;
  // text-align: center;

  // &-main {
  //   background-color: #c2e5ff;
  //   padding: 16px 8px;

  //   @media screen and (min-width: 960px) {
  //     padding: 16px 32px 32px 32px;
  //     line-height: 2;
  //   }
  // }

  &-row {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: centers;
    align-items: center;

    @media screen and (min-width: 960px) {
      padding: 20px;
    }
  }

  &-profile {
    position: relative;
    height: calc(100% - 80px);
    border-radius: 30px;
    padding: 16px 16px 32px 16px;
    border: 2px solid;
    border-color: #cecece;
    max-width: 500px;
    margin: auto;

    @media screen and (min-width: 960px) {
      min-height: 500px;
      max-width: 1000px;
      padding: 48px 24px;
      font-size: 0.9em;
      border: 3px solid;
      border-color: #cecece;
    }

    &-about {
      text-align: left;
      font-weight: bold;

      @media screen and (min-width: 960px) {
        margin-left: 100px;
        font-size: 1.1em;
      }
    }

    &-donguri {
      position: absolute;
      bottom: -10px;
      right: -10px;
      width: 20%;
      max-width: 150px;

      @media screen and (min-width: 960px) {
        bottom: 0;
        right: auto;
        left: -20px;
      }
    }
  }

  &-history {
    position: relative;
    border-radius: 30px;
    text-align: left;
    padding: 24px 8px;
    border: 2px solid;
    border-color: #cecece;
    max-width: 500px;
    margin: auto;

    @media screen and (min-width: 960px) {
      min-height: 500px;
      max-width: 1000px;
      padding: 48px 24px;
      border: 3px solid;
      border-color: #cecece;
    }

    &-table {
      @media screen and (min-width: 960px) {
        margin-left: 80px;
      }
    }

    &-detail {
      display: flex;
      position: relative;
      margin-bottom: 8px;
      margin-left: -8px;
      font-size: 0.9em;
      font-weight: bold;

      @media screen and (min-width: 960px) {
        font-size: 1.1em;
        gap: 12px;
      }
    }

    &-month {
      min-width: 42px;
      max-width: 50px;
    }
  }
}
</style>
