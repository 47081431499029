<template>
  <v-dialog v-model="isOpen" width="550px">
    <v-card class="pa-4">
      <v-card-title class="pa-4">
        <div class="form-title">以下の内容で送信します。よろしいですか？</div>
      </v-card-title>
      <div v-if="isEntry" class="pa-4">
        <div class="comfirm-label pa-3">お名前</div>
        <div class="comfirm-contents pa-3">{{ form.name }}</div>
        <div class="comfirm-label pa-3">フリガナ</div>
        <div class="comfirm-contents pa-3">{{ form.kana }}</div>
        <div class="comfirm-label pa-3">生年月日</div>
        <div class="comfirm-contents pa-3">{{ form.birth }}</div>
        <div class="comfirm-label pa-3">メールアドレス</div>
        <div class="comfirm-contents pa-3">{{ form.mail }}</div>
        <div class="comfirm-label pa-3">連絡先お電話番号</div>
        <div class="comfirm-contents pa-3">{{ form.phone }}</div>
        <div class="comfirm-label pa-3">お住まいの都道府県</div>
        <div class="comfirm-contents pa-3">{{ form.prefectures }}</div>

        <div v-if="form.type">
          <div class="comfirm-label pa-3">性別</div>
          <div class="comfirm-contents pa-3">{{ getType }}</div>
        </div>
        <div v-if="form.occupation">
          <div class="comfirm-label pa-3">応募職種</div>
          <div class="comfirm-contents pa-3">{{ form.occupation }}</div>
        </div>
        <div v-if="form.message">
          <div class="comfirm-label pa-3">メッセージ</div>
          <div class="comfirm-contents pa-3">{{ form.message }}</div>
        </div>
      </div>
      <div v-else class="pa-4">
        <div class="comfirm-label pa-3">お問合せ項目</div>
        <div class="comfirm-contents pa-3">{{ getType }}</div>
        <div class="comfirm-label pa-3">お名前</div>
        <div class="comfirm-contents pa-3">{{ form.name }}</div>
        <div v-if="form.kana">
          <div class="comfirm-label pa-3">フリガナ</div>
          <div class="comfirm-contents pa-3">{{ form.kana }}</div>
        </div>
        <div v-if="form.company">
          <div class="comfirm-label pa-3">企業名</div>
          <div class="comfirm-contents pa-3">{{ form.company }}</div>
        </div>
        <div v-if="form.phone">
          <div class="comfirm-label pa-3">連絡先お電話番号</div>
          <div class="comfirm-contents pa-3">{{ form.phone }}</div>
        </div>
        <div class="comfirm-label pa-3">メールアドレス</div>
        <div class="comfirm-contents pa-3">{{ form.mail }}</div>
        <div class="comfirm-label pa-3">お問い合わせ内容</div>
        <div class="comfirm-contents pa-3">{{ form.detail }}</div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="isOpen = false" width="20%">閉じる</v-btn>
        <v-spacer></v-spacer>
        <v-btn width="20%" @click="onClickSend">送信</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { FormTypes, SexTypes } from '@/const';

export default {
  props: {
    form: {
      type: Object,
      require: true,
    },
    isEntry: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getType() {
      if (this.form.type) {
        return this.isEntry ? SexTypes[this.form.type] : FormTypes[this.form.type];
      }
      return '';
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onClickSend() {
      this.$emit('onClickSend');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  width: 100%;
  text-align: center !important;
}

.comfirm-contents {
  border: 1px solid;
  border-radius: 4px;
  white-space: pre;
}
</style>
