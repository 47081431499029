<template>
  <v-form class="form">
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> お名前</v-col>
      <v-col>
        <v-text-field v-model="form.name" outlined :rules="requireRule" placeholder="必須入力です" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> フリガナ </v-col>
      <v-col>
        <v-text-field v-model="form.kana" outlined :rules="requireRule" placeholder="必須入力です" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> 生年月日</v-col>
      <v-col>
        <v-text-field v-model="form.birth" :rules="requireRule" outlined placeholder="必須入力です" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> メールアドレス</v-col>
      <v-col>
        <v-text-field v-model="form.mail" :rules="mailRule" outlined placeholder="必須入力です" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> 連絡先お電話番号 </v-col>
      <v-col>
        <v-text-field v-model="form.phone" :rules="phoneRule" outlined placeholder="必須入力です" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> お住まいの都道府県 </v-col>
      <v-col>
        <v-select
          v-model="form.prefectures"
          :items="prefectures"
          :rules="requireRule"
          outlined
          placeholder="必須入力です"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4">
        <div class="form-title">性別</div>
      </v-col>
      <v-col>
        <div>
          <v-radio-group class="form-radio-group" v-model="form.type" row>
            <v-radio v-for="(type, index) in sexTypes" :key="index" :label="sexTypes[index]" :value="index"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> 応募職種 </v-col>
      <v-col>
        <v-select v-model="form.occupation" :items="occupation" outlined />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="isScreenSmAndDown ? 12 : 4"> メッセージ </v-col>
      <v-col>
        <v-textarea v-model="form.message" outlined />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { SexTypes, Prefectures, Occupation } from '@/const';

export default {
  props: {
    form: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      requireRule: [(v) => !!v || '必須項目です'],
      mailRule: [
        (v) => !!v || '必須項目です',
        (v) =>
          /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/.test(v) ||
          'メールアドレス形式で入力してください',
      ],
      phoneRule: [(v) => !!v || '必須項目です', (v) => !v || /^0\d{9,10}$/.test(v) || '電話番号形式で入力してください'],
    };
  },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    sexTypes() {
      return SexTypes;
    },
    prefectures() {
      return Prefectures;
    },
    occupation() {
      return Occupation;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin: 0 auto;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

  @media screen and (min-width: 768px) {
    width: 80%;
    max-width: 700px;
  }

  &-radio-group {
    display: flex;
  }

  &-title {
    padding-top: 20px;
  }
}
</style>
