import Vue from 'vue';
import Vuex from 'vuex';
import news from './news';

Vue.use(Vuex);

const state = {
  viewPage: '',
  viewHeader: '',
  showTabButtons: true,
};

const mutations = {
  setViewPage(state, page) {
    state.viewPage = page;
  },
  setHeader(state, page) {
    state.viewHeader = page;
  },
  setShowTabButtons(state, value) {
    state.showTabButtons = value;
  },
};

const actions = {
  setViewPage: ({ commit }, { pageName = '' } = {}) => {
    commit('setViewPage', pageName);
  },
  setHeader: ({ commit }, { pageName = '' } = {}) => {
    commit('setHeader', pageName);
  },
  setShowTabButtons: ({ commit }, { value = false } = {}) => {
    commit('setShowTabButtons', value);
  },
};

const getters = {};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    news: news,
  },
});
