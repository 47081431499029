<template>
  <div class="contents-title">
    <v-img class="contents-title-img" :src="path" width="400" />
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: ''
    }
  },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>

<style lang="scss" scoped>
.contents {
  &-title {
    text-align: center;
    display: flex;
    position: relative;
    height: 50px;

    @media screen and (min-width: 960px) {
      height: 80px;
    }

    &-img {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}
</style>
